// Core settings
@import '__core/core-mixins';

// Helpers and utilities
@import '__helpers/util-classes';

// Site Structure
@import '_layout/_footer';

// Site Components
@import '_components/socials';

/* Custom Styles For The Project */
@import "project-custom/_custom__core";
