/* Media Queries Breakpoints */

$breakpoints: (
        'phone': 320px,
        'phone-l': 575px,
        'tablet': 767px,
        'desktop': 1112px,
        'desktop-m': 1280px,
        'desktop-l': 1440px,
        'desktop-xl': 1660px,
        'desktop-1920': 1920px,
);

/**
* *******************
*  Usage examples
* *******************
*
//
//  use <=
*   ALWAYS use <= ( '<' with '=' character) for all breakpoints
*   when setting max-width, ex: max-width: 767px,
*
*   so max-width: 1440px  == media('<=desktop-l')
*
* ***********************************************
*
//  use >
*   ALWAYS use only > as single character for all breakpoints
*   when setting min-width, ex: min-width: 768px,
*
*   so min-width: 1025px  == media('>desktop')
*
*************************************************
*
*## EXAMPLE:
*
*   (min-width: 768px) and (max-width: 1024px)
*   =
*   media ('>tablet'), ('<=desktop')
*
//

 */
