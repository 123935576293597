@use "sass:math";

@mixin aspect-ratio($width, $height) {
	position: relative;
	&:before{
		display: block;
		content: " ";
		width: 100%;
		padding-top: math.div($height, $width) * 100%;
	}

	> .content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

/*
.sixteen-nine {
	@include aspect-ratio(16,9);
}*/
