/* Settings */
:root {
  --base-transition: .3s; // default transition for hovers
  --l-block-gap-vertical: 15px;
  --l-block-gap-horizontal: 15px;
  --c-block-title-size: #{'clamp(var(--h4-font-size-mobile), var(--h-clamp-formula), var(--h4-font-size))'};
  --c-block-title-weight: var(--h4-font-weight);
  --c-block-title-transform: var(--h4-text-transform);
  --c-block-title-color: var(--titles-color);
  --c-block-text-size: var(--base-text-size);
  --с-block-padding: 15px;
  --c-block-align: left;
  --page-gutter: 20px;


    --navbar-height: 60px;
    @include media('>desktop') {
        --navbar-height: 136px;
    }
    --gl-shift: calc(50vw - var(--content-width) / 2);

    @include media('>desktop-1920') {
        --gl-shift: calc(50vw - var(--content-width) / 2);
    }
    --secondary-color4: #5f295f;
    --custom-gradient:linear-gradient(90.7deg, #003DA7 -102.29%, #00C2FF 29.58%);
}

/* WYSIWYG */
.is-wysiwyg {
  --wysiwyg-margin: 1.5em;
  --wysiwyg-li-padding: 0 0 0 30px;
  --wysiwyg-li-bgp: 0 .4em;
  --wysiwyg-li-margin: 1em;
  --wysiwyg-li-fallback: '\2022';
}

/* Social Icons */
.social-list {
  --social-icon-color: var(--primary-color2);
  --social-icon-color-hover: var(--secondary-color3);
  --social-icon-bg: var(--secondary-color3);
  --social-icon-bg-hover: var(--primary-color2);
  --social-icon-radius: 50%;
  --social-icon-size: 38px;
  --social-icon-inner: 55%;
}

/* Images */
.c-image {
  --front-src-shift: 30px; // Secondary decorative image offset
}

.read-more-wrapper {
  .read-more-toggle {
    margin-top: 10px;

    &::after {
      content: '';
      width: 17px;
      height: 17px;
      margin-left: 5px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml,%3Csvg width=\'17\' height=\'18\' viewBox=\'0 0 17 18\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Ccircle cx=\'8.5\' cy=\'8.51172\' r=\'8.5\' fill=\'%2336B6FF\'/%3E%3Cpath d=\'M8.66984 11.0609L13.0898 5.96094L8.66984 7.66094L4.24984 5.96094L8.66984 11.0609Z\' fill=\'%23001A48\'/%3E%3C/svg%3E%0A');
      transition: .3s ease;
    }
  }

  &.is-active {
    .read-more-toggle {
      &::after {
        transform: rotate(-180deg);
        transition: .3s ease;
      }
    }
  }
}

/* Load more posts button */
.pagination-container {
  .c-btn {
    &__ico {
      svg {
        transform: rotate(90deg);
      }
    }
  }
}


.site-content{
  position: relative;
  &:after{
    content: '';
    position: absolute;
    bottom: 0;
    pointer-events: none;
    z-index: -1;
    left: 0;
    width: 100%;
    height: 16vmin;
    background-image: linear-gradient(rgba(255,255,255,0), var(--footer-background-color, #FFF));
  }
}

.hide-tablet-less {
  @include media('<=tablet') {
    display: none;
  }
}

.hide-tablet-more {
  @include media('>tablet') {
    display: none;
  }
}