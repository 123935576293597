// clearfix
@mixin clearfix() {
    &:before, &:after {
        display: table;
        content: ' ';
    }
    &:after {
        clear: both;
    }
}

@mixin font-data($size: false, $lh: false, $weight: false, $font: false) {
    @if $size {
        font-size: $size;
    }
    @if $lh {
        line-height: $lh;
    }
    @if $weight {
        font-weight: $weight;
    }
    @if $font {
        font-family: $font;
    }
}

@mixin has-full-overlay($zindex: -1) {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(16, 16, 16, 0.15);
    backdrop-filter: blur(5px);
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
    z-index: $zindex;
}

@mixin has-overlay($opacity: false) {
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background-color: var(--primary-color1);
        opacity: $opacity;
        z-index: 1;
    }
}


@mixin cover-background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

// transition
@mixin transition {
    transition: all var(--base-transition) ease-in-out;
}

//// rotate
//@mixin rotate($deg) {
//  @include transform(rotate(#{$deg}deg));
//  outline: 1px solid transparent;
//  backface-visibility: hidden;
//}
//
//// scale
//@mixin scale($x,$y) {
//  @include transform(scale($x, $y));
//}
//
//// translate
//@mixin translate($x, $y) {
//  @include transform(translate($x, $y));
//}
//
//// skew
//@mixin skew($x, $y) {
//  @include transform(skew(#{$x}deg, #{$y}deg));
//  outline: 1px solid transparent;
//  backface-visibility: hidden;
//}


@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    }
    &:-moz-placeholder {
        @content
    }
    &::-moz-placeholder {
        @content
    }
    &:-ms-input-placeholder {
        @content
    }
}

@mixin gradient-horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
    background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
    background-repeat: repeat-x;
}

// Vertical gradient, from top to bottom
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin gradient-vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
    background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
    background-repeat: repeat-x;
}

@mixin gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) {
    background-repeat: repeat-x;
    background-image: linear-gradient($deg, $start-color, $end-color); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
}

@mixin gradient-horizontal-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
    background-image: linear-gradient(to right, $start-color, $mid-color $color-stop, $end-color);
    background-repeat: no-repeat;
}

@mixin gradient-vertical-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
    background-image: linear-gradient($start-color, $mid-color $color-stop, $end-color);
    background-repeat: no-repeat;
}

@mixin gradient-radial($inner-color: #555, $outer-color: #333) {
    background-image: radial-gradient(circle, $inner-color, $outer-color);
    background-repeat: no-repeat;
}

@mixin gradient-striped($color: rgba(255,255,255,.15), $angle: 45deg) {
    background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
}
