.site-footer {
    position: relative;
    background-color: var(--footer-background-color);
}

.l-footer {
    border-top: var(--footer-top-border-size) solid var(--footer-top-border);
    color: var(--footer-color);
    padding-bottom: calc(env(safe-area-inset-bottom) + 0px); // fix for iOS devices
    overflow: hidden;
    position: relative;
    z-index: 2;

    a {
        color: var(--footer-links-color);

        &:hover {
            text-decoration: underline;
        }

        @include media("<=desktop") {
            text-decoration: underline;
        }
    }

    .social-list {
        gap: var(--footer-link-gap_h, 1vw);
    }

    /* Footer Media */

    &__media {
        position: absolute;
        pointer-events: none;
        overflow: hidden;

        img, video {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__video {
        height: 100%;

        &:before {
            content: '';
            position: absolute;
            inset: 0;
            background-color: rgba(0 0 0 / .6);
        }
    }

}


/* Footer Top Row */

.footer-top {
    padding-block: var(--footer-top-padding, 20px) var(--footer-bottom-padding, 20px);
    font-size: var(--footer-top-font-size);

    &__col {
        @include media("<=tablet") {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }

    &__menus {
        @include media(">desktop") {
            display: flex;
            justify-content: flex-end;
        }
    }
}

/* Footer Bottom Row */

.footer-bottom {
    padding-block: var(--footer-bottom-padding, 10px);
    font-size: var(--footer-bottom-font-size);
    background-color: var(--footer-background-color-bottom);
    &__inner {
        @include media(">tablet") {
            display: flex;
            justify-content: space-between;
        }
    }
}

/* Small components */

.footer-nav {
    .current-menu-item > a {
        color: var(--primary-color2);
    }

    &.h-direction {
            display: flex;
            align-items: center;
            gap: var(--footer-link-gap_h, 1vw);
        @include media('<=tablet'){
            justify-content: center;
            gap: 30px;
        }
    }

    &.v-direction {
        li {
            margin-bottom: var(--footer-link-gap_v);
        }
    }
}

.footer-title {
    font-weight: var(--footer-title-weight);
    font-size: var(--footer-title-size);
    text-transform: var(--footer-title-transform);
}

.footer-logo {
    @include media("<=desktop") {
        min-height: 25px;
        max-height: 80px;
    }
    height: var(--footer-logo-height);
    width: var(--footer-logo-width);
}

.footer-map {
    iframe, image, picture {
        max-width: 100%;
        @include media("<=desktop") {
            max-height: 250px;
        }
    }
}
