/* Footer */
.site-footer {
  --footer-top-padding: 5vh;
  --footer-top-font-size: 1.6rem;
  --footer-bottom-padding: 1.2em;
  --footer-bottom-font-size: 1.6rem;
  --footer-link-gap_v: .8em;
  --footer-link-gap_h: 2vw;
  --footer-logo-height: auto;
  --footer-logo-width: 119px;
  --footer-title-size: 2rem;
  --footer-title-weight: 600;
  --footer-title-transform: uppercase;
}

.footer-logo-link {
  display: block;
  margin-bottom: calc(2.4rem * 1.25);
  @include media('>desktop') {
    margin-block: 4.5rem 6.655rem;
  }
  @include media('<=desktop') {
    margin-inline: auto;
    width: fit-content;
    --footer-logo-width: calc(9.658rem * 1.25)
  }
}

.footer-title {
  margin-bottom: .5em;
}

.footer-block {
  width: min(43.2rem, 100%);
  @include media('<=desktop-m') {
    width: min(63.2rem, 100%);
    margin-inline: auto;
  }
  @include media('>phone-l') {
    gap: 0 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "a b " "a c";
  }

  &__item {
    padding-block: 0 calc(2.7rem * 1.25);
    @include media('>desktop') {
      padding-block: 2.1rem;
    }
    @include media('>phone-l') {
      grid-area: b;
    }

    &:first-of-type {
      @include media('<=desktop') {
        padding-bottom: calc(3.4rem * 1.25);
      }
      @include media('>phone-l') {
        grid-area: a;
      }
    }

    &:last-of-type {
      @include media('>phone-l') {
        grid-area: c;
      }
    }

    .footer-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 1.5;
      color: var(--primary-color2);
      text-transform: unset;
      margin-bottom: 6px;
      @include media('<=desktop') {
        margin-bottom: 10px;
      }
    }

    .contact-info,
    .bid-info {
      &__item {
        line-height: 1.1875;
        margin-bottom: 1.5rem;
        @include media('>desktop') {
          display: flex;
          align-items: center;
          justify-content: start;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &__link {
        text-decoration: none;
        @media (hover: hover) {
          &:hover {
            color: var(--primary-color2);
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.footer-text {
  margin-bottom: 2em;
}

.site-footer {
  padding-block: calc(11rem * 1.25) calc(4rem * 1.25);
  @include media(">desktop") {
    padding-block: 11.11vmin 3.5rem;
  }

  &.--simpler{
    @include media(">desktop") {
      padding-block: 5.5vmin 3.5rem;
    }
    .l-footer{
      @include media('>desktop'){
        min-height: 34.2vw;
      }
      &__media{
        @include media('>desktop'){
          height: calc(100% - 5.5vmin);
        }
      }
    }
  }

  .social-list {
    gap: 1vw;
    --social-icon-bg: var(--primary-color1);
    --social-icon-color: var(--secondary-color3);
    @include media('>phone-l') {
      justify-content: start;
    }
    @include media('<=desktop') {
      margin-bottom: calc(1.6rem * 1.25);
      gap: calc(2rem * 1.25);
    }

    &__link {
      background-color: var(--social-icon-bg);
      color: var(--social-icon-color);
      width: var(--social-icon-size);
      height: var(--social-icon-size);
      @include transition;

      &:hover {
        background-color: var(--primary-color2);
      }
    }
  }
}

.l-footer {

  @include media("<=tablet") {
    text-align: center;
  }

  &__media {
    @include media(">desktop") {
      width: 55.5%;
      right: 0;
      height: calc(100% - 11.11vmin);
      background-size: contain;
    }

    @include media("<=desktop") {
      display: none;
    }

    img {
      @include media(">desktop") {
        object-fit: contain;
        object-position: bottom right;
      }
    }
  }
}

.footer-top {
  padding-block: 0;
  @include media(">desktop") {
    padding-bottom: 4.8rem;
    &__inner {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }

    &__col {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;

      &:first-of-type {
        max-width: min(51.8rem, 100%);
      }
    }
  }
}

.footer-bottom {
  &__inner {
    font-weight: 400;
    font-size: 14px;
    @include media('>desktop') {
      justify-content: start;
    }

    .footer-nav {
      @include media('>desktop') {
        margin-left: 6.6rem;
      }
      @include media('<=phone-l') {
        margin-top: 25px;
      }

      a {
        text-decoration: none;
        @media (hover: hover) {
          &:hover {
            color: var(--primary-color2);
          }
        }
      }
    }
  }
}

.footer-form {
  .gform_wrapper.gravity-theme {
    .gfield_label {
      display: none;
    }

    textarea {
      max-height: 110px;
    }

    .gform_footer {
      margin: 0;
    }
  }
}

.contact-info {
  &__icon {
    height: 20px;
    width: 20px;
    min-width: 20px;
    color: var(--primary-color2);
    fill: var(--primary-color2);
    margin-right: 8px;
  }
}
